export default {
    zh:{
        reloadcache:{
            title:'缓存重载',
            reloadTip:'确定重新加载缓存吗?',
            selectTip:'请选择要重新加载的记录',
            columns:{
                operate:'操作',
                reload:'重新加载',
                F_CAPTION:'缓存名',
                F_SCLASS:'缓存类',
            },
        }
    },
    en:{
        reloadcache:{
            title:'reload cache',
            reloadTip:'Are you sure to reload the cache?',
            selectTip:'please select a row',
            columns:{
                operate:'operate',
                reload:'reload',
                F_CAPTION:'Cache Name',
                F_SCLASS:'Cache Class',
            },
        }
    }
}